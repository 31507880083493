import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "reach-ui"
    }}>{`Reach UI`}</h1>
    <p>{`Reach UI seeks to become the accessible foundation of your React-based design system.`}</p>
    <p>{`You can get involved by:`}</p>
    <ul>
      <li parentName="ul">{`Joining the `}<a parentName="li" {...{
          "href": "https://spectrum.chat/reach"
        }}>{`Spectrum Community`}</a></li>
      <li parentName="ul">{`Following on `}<a parentName="li" {...{
          "href": "https://github.com/reach/reach-ui"
        }}>{`GitHub`}</a></li>
    </ul>
    <p>{`Each component is tested with Safari + VoiceOver, Firefox + NVDA, and Edge + JAWS. As the project matures we’ll get it audited by WebAIM to ensure that if you pick Reach UI, your app has a solid, accessible foundation.`}</p>
    <p>{`Development of Reach UI is funded by `}<a parentName="p" {...{
        "href": "https://reach.tech/courses"
      }}>{`online courses`}</a>{` and `}<a parentName="p" {...{
        "href": "https://reacttraining.com/workshops/"
      }}>{`workshops`}</a>{` provided by `}<a parentName="p" {...{
        "href": "https://reacttraining.com/training/"
      }}>{`the React Training team`}</a>{`.`}</p>
    <h2 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h2>
    <ol>
      <li parentName="ol">{`Read the `}<a parentName="li" {...{
          "href": "/styling"
        }}>{`Styling Guide`}</a></li>
      <li parentName="ol">{`Read the docs for a component, how about the `}<a parentName="li" {...{
          "href": "/menu-button"
        }}>{`MenuButton`}</a>{`?`}</li>
      <li parentName="ol">{`Many of the examples in the docs are interactive, so go ahead and tinker!`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      